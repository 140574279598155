@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Open Sans' !important; */
  /* font-family: var(--mdb-font-roboto); */
  height: 100vh;
  background-color: #f7f1f1;
  letter-spacing: .005em;
  line-height: 150%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.CodeBenderPrimaryColor{
  color: #F26B3A;
}
.container-bg {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  background-color: #e4e4e4 !important;
}
.thetitle{ 
  color: #0a0402; 
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 30px;
  line-height:1.3;
}
.thetitle span{ 
  background-color: #F26B3A;
  color: #FFF;
  line-height: 1.8;
  padding: 0 3px;
  border-radius: 3px;
}
.thetitle-two{  
  font-family: 'Lato', sans-serif; 
  font-size: 34px; 
  font-weight: bold; 
  line-height: 38px; 
  margin-top: 98px; 
}
.title{ 
  color: #F26B3A; 
  font-family: 'Lato', sans-serif; 
  font-size: 22px; 
  line-height: 38px;
  text-align: center; 
}
.content-title{
  color: #3c3c45; 
  font-family: 'Lato', sans-serif; 
  font-size: 24px;  
  line-height: 38px;
  margin: 10px; 
}

.textCodebenderThreeD {
  /* position:relative; */
  /* top: 20%;  */
  right: 50%;
  margin-top: 90px;
  /* transform: translate(50%,-50%); */
  text-transform: uppercase;
  font-family: verdana;
  font-size: 5em;
  font-weight: 700;
  color: #FFF;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #F26B3A,
      1px 5px 1px #F26B3A,
      1px 6px 1px #F26B3A,
      1px 7px 1px #F26B3A,
      1px 8px 1px #F26B3A,
      1px 9px 1px #f5b69f,
      1px 10px 1px #f5b69f,
  1px 18px 6px #f5b69f,
  1px 22px 10px #f5b69f,
  1px 25px 35px #f5b69f,
  1px 30px 60px #f5b69f;
}
.moreInfo{
  top: 50%;
  text-align: left;
  padding-bottom: 32px;
  padding-bottom: var(--size-32);
  padding-top: 32px;
  padding-top: var(--size-32);
  /* background: rgb(243, 243, 243); */
  opacity: 19px;
  /* background: linear-gradient(252.32deg,#485dc7,#37517e),linear-gradient(0deg,#475cc7,#475cc7); */
}
.test-title{
  font-size: 2em;
  top: 50%; 
}
.menu-list{
  color: #3c3c45; 
}
.menu-list:hover{
  color: #F26B3A; 
}
.btn-two {
  padding: 0.4em;
  border-radius: 4px;
  font-size: .7em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-color: transparent;
  cursor: pointer;
  text-decoration: none;
}
.start-btn {
  padding: 0.5em;
  border-radius: 4px;
  font-size: .9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  border-color: transparent;
  background-color: rgb(70, 169, 151);
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #000;
}

p {
  font-size: 20px;
   margin: 0 0 32px;
   line-height: 1.7;
}

ul {
  list-style: none;
}

li > a{
  font-family: 'Open Sans' !important;
  font-weight: 100;
  font-size: 15px;
  letter-spacing: 0px;
}
a:hover{
  color: #44aa97;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}
.main-container{
  padding: 20px;
  border: 2px #F26B3A solid;
  background-color: #000;
}
.table-container{
  margin-top: 20px;
  border: 2px #F26B3A solid;
  background-color: #FFF;
  text-align: justify;
  box-shadow: 0 2px 5px 0 rgba(50, 48, 48, 0.2), 0 2px 10px 0 rgb(0 0 0 / 10%);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 60px;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}
.form,
.content {
  width: 70%;
  margin: 0 auto;
  margin-top: 19px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}
.user-display-name{
  /* font-size: 12px; */
  text-align: left;
  margin-top: -10px;
  color: #084d40;
}
.user-display-score{
  float: right;
  text-align: right;
  margin-top: -55px;
  color: #084d40;
}
.score-text{
  color: #d9534f;
  font-weight: bold;
  font-size: .95rem;
  margin-left: 9px;
}
.timer-text{
  color: #d9534f;
  font-weight: bold;
  font-size: .95rem;
  margin-left: 29px;
}
/* .btn {
  padding: 10px 20px;
  border: 1px solid #44aa97;
  border-radius: 5px;
  background: #44aa97;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.btn {
  text-transform:inherit;
  vertical-align: bottom;
  border: 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
  font-weight: 500;
  padding: 0.625rem 1.5rem 0.5rem;
  font-size: .95rem;
  line-height: 1.5;
  border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.color-primary {
  color: #6d87dd;
}
.color-success {
  color: #5cb85c;
}
.color-danger {
  color: #d9534f;
}
.color-light {
  color: #f9f9f9;
}
.color-warning {
  color: #ffa900;
}
.color-info {
  color: #39c0ed;
}
.btn-primary {
  color: #fff;
  background-color: #6d87dd;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
}
.btn-light {
  color: #4f4f4f;
  background-color: #f9f9f9;
}
.btn-warning {
  color: #fff;
  background-color: #ffa900;
}
.btn-info {
  color: #fff;
  background-color: #39c0ed;
}
.btn-secondary {
  color: #fff;
  background-color: #b23cfd;
}
.btn-dark {
  color: #fff;
  background-color: #262626;
}
.primary-color{
  color: #fff;
  background-color: #6d87dd;
}
.btn-primary-two{
  color: #fff;
  background-color: #6d87dd;
}
.btn-primary-two:hover{
  color: #fff;
  background-color: #4669dd;
}
.btn-success-two{
  color: #fff;
  background-color: #5cb85c;
}
.btn-danger-two{
  color: #fff;
  background-color: #d9534f;
}
.btn-light-two{
  color: #4f4f4f;
  background-color: #f9f9f9;
}
.btn-warning-two{
  color: #fff;
  background-color: #ffa900;
}
.btn-info-two{
  color: #fff;
  background-color: #39c0ed;
}
.btn-secondary-two{
  color: #fff;
  background-color: #b23cfd;
}
.btn-dark-two{
  color: #fff;
  background-color: #262626;
}
.btn svg {
  margin-right: 8px;
}
.btn-reverse {
  background: #fff;
  color: #000;
}
.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
.questionBox{
  background-color: #FFF;
  color: #73C2FB;
  margin-left: 5px;
  text-align: center;
  padding: 7px;
  border-radius: 3px;
  border: 1px #a6d9fd solid;
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px;
}
.questionBox-two{
  background-color: #FFF;
  color: #fb7448;
  margin-left: 3px;
  text-align: center;
  padding: 7px;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px;
}
.form-control{
  font-size: 33px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}
.game-timer{
  /* margin-top: -19px; */
  margin-bottom: 19px;
  margin-left: 19px;
}
.countdown-timer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  /* font-size: 1.75rem; */
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  background-color: #FFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #de4848;
  -moz-box-shadow: 0 0 3px #FFF;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}
.circle{
  position:relative;
  width:50%;
  padding-bottom:50%;
  background:#FFFF;
  border-radius:50%;
  color: #44aa97;
}
.circle h3{
  position:absolute;
  top:50%; left:50%;
  transform: translate(-50%, -50%);
  margin:0;
  font-size: 47px;
}

.circle p{
  font-size: 14px;
}
.link{
  color: #46a997;
  cursor: pointer;
  text-decoration: none;
}
.nav-primary-link {
  font-family: var(--font-text);
  font-size: 19px;
  letter-spacing: -.02em;
  line-height: 120%;
}
.nav-primary-link:hover {
  color: #000;
  text-decoration: underline;
}
.red-text{
  color: #de4848;
}
.green-text{
  color: #46a997;
}
.yellow-text{
  color: #cfcf0d;
}
.copy-test-link{
  color: #46a997;
  border: 2px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
}
.section {
  padding: 20px 83px 50px;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}
.company-signin-form {
  padding: 20px;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #b5c822 0%,
    #44107a 29%,
    #51cc74 67%,
    #13d757 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 33px;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.defaultRCtab{
  color: rgba(0, 0, 0, 0.66);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  font-weight:700;
  text-decoration: none;
  margin-right: 30px;
  padding-bottom: 15px;
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-size: 12px;
}
.colorRCtab{
  color: #3367d6;
  border-bottom: 2px #3367d6 solid;
  border-width: 1px 1px 2px 6px;
  font-weight:700;
  text-decoration: none;
  margin-right: 30px;
  padding-bottom: 15px;
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-size: 12px;
}
.rcDescription{
  text-align: justify;
  font-size: 12;
  padding: 10px;
}
.advertImage{
  margin-top: 70px;
}
.api-ref-content{
  background-color: #FFF;
  padding: 25px;
  border-radius: 3px;
  text-align: justify;
}
.api-ref-content > p{
  font-size: 14px;
}
.rc-api-table-content{
  color: #1a73e8;
  font-size: 12px;
  text-align: left;
}
.create-api-key-warning-msg{
  font-size: 12px;
  background-color: #cfe2ff;
  color: #084298;
  border-color: #b6d4fe;
  padding: 5px;
  border-radius: 4px;
}
.deactivate-api-key-warning-msg{
  font-size: 12px;
  background-color: #f8d7da;
  color: #842029;
  border-color: #f8d7da;
  padding: 5px;
  border-radius: 4px;
}
.recaptcha-nav {
  background-color: #FFF;
  padding: 3px;
  border-radius: 3px;
  text-align: left;
  font-size: 14px;
}
#recaptcha-side-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
#recaptcha-side-nav li {
  display: block;
  /* border-bottom: 1px solid #ccc; */
}
#recaptcha-side-nav a {
  display: block;
  margin-top: -1px;
  margin-left: 4px;
  padding: 3px;
  color: #000;
  text-decoration: none;
}
#recaptcha-side-nav a:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
}
.samplecode-div{
  text-align: left;
  border-radius: 9px;
  background-color: #FFF;
  padding: 30px;
}
.samplecode-div >h3{
  margin-bottom: 30px;
}
.btn-game-play-instruction{
  text-align: center;
}
.title-apikey{
  color: #202123;
  font-weight: 700;
  margin-bottom: 30px;
}
/* Example media query for mobile view */
@media (max-width: 767px) {
  /* Set logo display to block */
  .thetitle {
    display: block;
  }
  
  /* Set menu display to none */
  .d-md-flex {
    display: none;
  }
}
#apikeyNavBtn{
  color:#1a73e8;
  background-color: #d2e3fc;
  border-radius: 3px;
  padding: 4px;
  margin-right: 9px;
  border: 2px #d2e3fc solid;
}
.list-unstyled > li > a{
  text-align: left;
  color: inherit;
  text-decoration: none;
}